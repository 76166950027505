import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { combineLatest, forkJoin, merge, Observable } from 'rxjs';
import { map, mergeAll, tap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { SystemService } from '../services/system.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router, private systemService: SystemService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return combineLatest([
      this.authService.isAdmin$,
      this.authService.isCashier$,
      this.authService.isWarehouseAdmin$
    ]).pipe(
      map(([isAdmin, isCashier, isWarehouseAdmin]) => isAdmin || isCashier || isWarehouseAdmin),
      tap(isAllowed => {
        if (!isAllowed) this.backToLogin();
      })
    );
  }

  private showErrorToast() {
    alert('You do not have sufficient privileges to view this page');
  }

  backToLogin() {
    this.showErrorToast();
    this.authService.logout();
    this.router.navigate(['/auth']);
  }
}
