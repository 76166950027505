import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SystemService } from './shared/services/system.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'frontend-v3';

  constructor(private systemService: SystemService) {}
}
