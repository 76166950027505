export function pad(value: number | string | undefined, length = 5) {
  let str = '' + value;
  while (str.length < length) {
    str = '0' + str;
  }
  return `${str}`;
}

export function exists<T>(value: T | null | undefined): value is T {
  return value !== undefined && value !== null;
}
