import { Injectable } from '@angular/core';

export enum LocalStorageKeys {
  INVOICES = 'INVOICES',
  PACKAGES = 'PACKAGES',
  WAREHOUSE_RESPONSE = 'WAREHOUSE_RESPONSE',
  CUSTOMERS = 'CUSTOMERS',
  CUSTOMERS_PACKAGE = 'CUSTOMERS_PACKAGE',
  DASHBOARD_DATA = 'DASHBOARD_DATA'
}
@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  private cacheDuration = 15 * 60 * 1000; // 15 minutes in milliseconds

  constructor() {}

  // Save an item to local storage with a timestamp
  setItem<T>(key: LocalStorageKeys, value: T): void {
    try {
      const timestampedValue = {
        value: value,
        timestamp: new Date().getTime()
      };
      const jsonValue = JSON.stringify(timestampedValue);
      localStorage.setItem(key, jsonValue);
    } catch (e) {
      console.error(`Error saving to localStorage: ${e}`);
    }
  }

  // Retrieve an item from local storage, checking the cache duration
  getItem<T>(key: LocalStorageKeys): T | null {
    try {
      const jsonValue = localStorage.getItem(key);
      if (jsonValue) {
        const timestampedValue = JSON.parse(jsonValue);
        const currentTime = new Date().getTime();
        if (currentTime - timestampedValue.timestamp < this.cacheDuration) {
          return timestampedValue.value as T;
        } else {
          this.removeItem(key);
        }
      }
      return null;
    } catch (e) {
      console.error(`Error getting data from localStorage: ${e}`);
      return null;
    }
  }

  // Remove an item from local storage
  removeItem(key: LocalStorageKeys): void {
    localStorage.removeItem(key);
  }

  // Clear all local storage
  clear(): void {
    localStorage.clear();
  }
}
