import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { filter, shareReplay, switchMap, takeUntil, tap, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { Courier, CourierResolvers, LoopbackFilter } from '../models/api-models';
import { SwUpdate } from '@angular/service-worker';
import { Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { getCustomHeaders } from '../utils';
import { DashboardResponse } from 'src/app/pages/dashboard/dashboard.component';
import { exists } from '../helpers/common_helpers';

@Injectable({
  providedIn: 'root'
})
export class SystemService {
  COURIER_KEY = 'COURIER';
  BASE_URL = `${environment.apiUrl}/${environment.apiVersion}/`;
  RESOLVER_ENDPOINT = 'CourierResolvers/findOne';
  COURIER_ENDPOINT = 'Couriers';

  private courier: BehaviorSubject<Courier> | undefined;
  private courierCached$: Observable<Courier> | null = null; // Caching the courier$ observable
  updated$ = new Subject<void>();
  private static courierResolverCached$: Observable<CourierResolvers> | null = null; // Static cache
  private courierByIdCache: Map<number, Observable<Courier>> = new Map();
  private updateAvailable$ = new Subject<void>();

  constructor(
    private http: HttpClient,
    private swUpdate: SwUpdate,
    private titleService: Title,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.checkForUpdate();
    this.clearCourier();
    const courierExists = localStorage.getItem(this.COURIER_KEY);
    if (courierExists) {
      this.courier = new BehaviorSubject(JSON.parse(courierExists));
    }
  }

  get courier$(): Observable<Courier> {
    // If we already have a cached observable, return it
    if (this.courierCached$) {
      return this.courierCached$;
    }

    // If the courier already exists, create and cache the observable
    if (this.courier) {
      this.courierCached$ = this.courier.asObservable().pipe(
        tap(c => this.setCourier(c)),
        shareReplay(1) // Ensure it's cached for future subscriptions
      );
      return this.courierCached$;
    }

    // Otherwise, fetch and cache the courier using resolver
    const resolver = this.getCourierResolver();
    this.courierCached$ = resolver.pipe(
      filter(exists),
      switchMap(courierResolver => {
        return this.getCourier(courierResolver.courierId).pipe(
          tap(courier => this.setCourier(courier)),
          shareReplay(1) // Cache the observable for future subscribers
        );
      })
    );

    return this.courierCached$;
  }

  getDashboardData(request: {
    courierId: number;
    dateFrom?: Date;
    dateTo?: Date;
    storeLocationId?: number;
  }): Observable<DashboardResponse> {
    return this.http.post(
      `${this.BASE_URL}${this.COURIER_ENDPOINT}/getDashboardData`,
      { ...request },
      {
        headers: getCustomHeaders()()
      }
    ) as Observable<DashboardResponse>;
  }

  private getCourierResolver(): Observable<CourierResolvers> {
    // Return static cached observable if it exists
    if (SystemService.courierResolverCached$) {
      return SystemService.courierResolverCached$;
    }

    const filter = {
      where: {
        or: [
          {
            adminUrl: location.host
          },
          {
            customerUrl: location.host
          }
        ]
      }
    };

    // Create and cache the resolver observable using static cache
    SystemService.courierResolverCached$ = this.http
      .get<CourierResolvers>(`${this.BASE_URL}${this.RESOLVER_ENDPOINT}?filter=${JSON.stringify(filter)}`, {
        headers: getCustomHeaders()()
      })
      .pipe(
        shareReplay({ bufferSize: 1, refCount: false }) // Ensure cache persists even when all subscribers unsubscribe
      );

    return SystemService.courierResolverCached$;
  }

  // Update clearResolverCache to clear static cache
  clearResolverCache() {
    SystemService.courierResolverCached$ = null;
  }

  private setCourier(courier: Courier) {
    // Cache the courier once it's fetched and available
    this.courier = new BehaviorSubject(courier);
    localStorage.setItem(this.COURIER_KEY, JSON.stringify(courier));

    this.titleService.setTitle(courier.name || 'Ox Admin');
    if (courier.id && environment.production) {
      this.createManifest(courier.id);
      const favIconUrl = courier.imageUrl
        ? courier.imageUrl
        : `${environment.apiUrl}/${environment.apiVersion}/Storages/${courier.id}-app-icons/download/icon-144x144.png`;
      this.document?.getElementById('appFavicon')?.setAttribute('href', favIconUrl);
    }
  }

  private createManifest(id: number): void {
    console.log('Creating manifest');
    const link = document.createElement('link');
    link.href = `${environment.apiUrl}/${environment.apiVersion}/Couriers/getManifestFile?courierId=${id}&host=${location.origin}`;
    link.rel = 'manifest';
    document.getElementsByTagName('head')[0].appendChild(link);
  }

  private getCourier(id: number): Observable<Courier> {
    // Check cache first
    const cached = this.courierByIdCache.get(id);
    if (cached) {
      return cached;
    }

    const filter: LoopbackFilter = {
      include: ['courierSetting', 'themeSetting', 'mailOption', 'rewardSetting']
    };

    // Create and cache the request
    const request = this.http
      .get<Courier>(`${this.BASE_URL}${this.COURIER_ENDPOINT}/${id}?filter=${JSON.stringify(filter)}`, {
        headers: getCustomHeaders()()
      })
      .pipe(shareReplay(1));

    this.courierByIdCache.set(id, request);
    return request;
  }

  checkForUpdate() {
    // Only subscribe if we haven't already
    if (!this.updateAvailable$.closed) {
      this.swUpdate.available
        .pipe(
          takeUntil(this.updateAvailable$),
          take(1) // Take only the first emission
        )
        .subscribe(() => {
          this.showUpdateAvailable();
        });
    }
  }

  showUpdateAvailable() {
    const confirmation = confirm('A system update is available, do you want to update now?');
    if (confirmation) {
      this.update();
    } else {
      // Reset the update available subject if user declines
      this.updateAvailable$.next();
      this.updateAvailable$.complete();
    }
  }

  update() {
    this.swUpdate.activateUpdate().then(() => {
      this.updateAvailable$.next();
      this.updateAvailable$.complete();
      location.reload();
    });
  }

  clearCourier() {
    this.courier = undefined;
    this.courierCached$ = null;
    SystemService.courierResolverCached$ = null;
    this.courierByIdCache.clear();
    localStorage.removeItem(this.COURIER_KEY);
  }

  getPackagesFromWarehouse(time: string): Observable<any> {
    return this.courier$.pipe(
      filter(exists),
      take(1),
      switchMap(courier =>
        this.http.get(`${this.BASE_URL}${this.COURIER_ENDPOINT}/fetchWarehousePackages`, {
          params: {
            courierId: courier!.id!.toString(),
            time
          },
          headers: getCustomHeaders()()
        })
      )
    );
  }

  updateCourier(id: number, courierData: Partial<Courier>) {
    return this.http.patch(`${this.BASE_URL}${this.COURIER_ENDPOINT}/${id}`, courierData, {
      headers: getCustomHeaders()()
    });
  }
}
